const scrollButtons = document.querySelector('.scrolling-buttons');
const leftController = document.querySelector('button.left');
const rightController = document.querySelector('button.right');

function checkPosition() {
    /**
     * checkPosition: sees where the scrollLeft is and hides/shows
     * right left click arrows as needed
     */
    const currentPosition = scrollButtons.scrollLeft;
    const buttonsWidth = scrollButtons.clientWidth;
    const { scrollWidth } = scrollButtons;
    // hide left
    if (currentPosition === 0 || currentPosition <= 30) {
        leftController.classList.add('d-none');
        scrollButtons.classList.add('no-left-margin');
    } else {
        leftController.classList.remove('d-none');
        scrollButtons.classList.remove('no-left-margin');
    }

    // hide right
    if ((currentPosition + buttonsWidth) > (scrollWidth - 30)) {
        rightController.classList.add('d-none');
    } else {
        rightController.classList.remove('d-none');
    }
}

function leftScroll() {
    /**
     * leftScroll: Function to scroll left 200px
     */
    scrollButtons.scrollBy({ top: 0, left: -300, behavior: 'smooth' });
    setTimeout(checkPosition, 250);
}

function rightScroll() {
    /**
     * rightScroll: Function to scroll right 200px
     */
    scrollButtons.scrollBy({ top: 0, left: 300, behavior: 'smooth' });
    setTimeout(checkPosition, 250);
}

function getActiveInView() {
    /**
     * getActiveInView: Function to check if left side of active element is in view
     *  and scroll it into view if it's out of view
     */
    if (document.querySelector('.btn-topic.selected')) {
        const endOfActive = document.querySelector('.btn-topic.selected').offsetLeft + document.querySelector('.btn-topic.selected').offsetWidth;

        if (endOfActive > scrollButtons.clientWidth) {
            const scrollAmount = endOfActive - scrollButtons.clientWidth + 10;
            scrollButtons.scrollBy({ top: 0, left: scrollAmount, behavior: 'smooth' });
        }
    }
    setTimeout(checkPosition, 250);
}

document.addEventListener('readystatechange', (event) => {
    /**
     * on page load:
     * 1. set the click handlers for the left and right scrollers
     * 2. scroll active into view
     */
    if (event.target.readyState === 'complete') {
        leftController.addEventListener('click', () => {
            leftScroll();
        });
        rightController.addEventListener('click', () => {
            rightScroll();
        });

        // scroll active page into position
        setTimeout(getActiveInView, 250);
    }
});
